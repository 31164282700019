import React from 'react'

export const pt = {
  advantages: {
    title: 'Quais as vantagens da Código_Font?',
    subtitle: 'Outras',
    items: [
      {
        id: 0,
        content: (
          <>
            Empatia e com a sua dor <span>e a busca por uma 
            solução que faça sentido para sua realidade e 
            que gere resultados.</span>
          </>
        )
      },
      {
        id: 1,
        content: (
          <>
            Estratégia de Custo escalonável e reduzido
            <span> no desenvolvimento.</span>
          </>
        )
      },
      {
        id: 2,
        content: (
          <>
            Comunicação constante e transparência,
            <span> deixando você imerso no projeto.</span>
          </>
        )
      },
    ]  
  }
}

export const en = {
  advantages: {
    title: 'What are the advantages of the Código_Font?',
    subtitle: 'Others',
    items: [
      {
        id: 0,
        content: (
          <>
            Empathize with your pain <span> and the search for a solution that makes sense for your company and generates results.</span>
          </>
        )
      },
      {
        id: 1,
        content: (
          <>
            Scalable and reduced cost strategy
            <span> in development.</span>
          </>
        )
      },
      {
        id: 2,
        content: (
          <>
            Constant communication and transparency,
            <span> leaving you immersed in the project.</span>
          </>
        )
      },
    ]  
  }
}