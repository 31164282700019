import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'

import './styles.css'

import ptFlag from '../../assets/languages/pt.svg'
import enFlag from '../../assets/languages/en.svg'

import LanguageContext from '../../utils/LanguageContext'
import { pt, en } from '../../languages/Navbar'

import bubble_1 from '../../assets/bubble-1.png'
import bubble_2 from '../../assets/bubble-2.png'

const Home = () => {

  const context = useContext(LanguageContext)

  const pages = context.language === 'pt' ? pt.pages : en.pages 
  const slogan = context.language === 'pt' ? pt.slogan : en.slogan 

  return (
    <div className='home-container'>
      <img src={bubble_1} alt="bubble-01" className='bubble-01' draggable={false} />
      <img src={bubble_2} alt="bubble-02" className='bubble-02' draggable={false} />

      <div className="header">
        <Link to={pages[2].url}>{pages[2].title}</Link>
        <img 
          className='language' 
          src={context.language === 'pt' ? ptFlag : enFlag} 
          alt="language"
          onClick={context.toggleLanguage}
        />
      </div>

      <div className="content">
        <h1>
          <span>{'<'}</span>
            código<span>{'_'}</span>font
          <span>{'>'}</span>
        </h1>
        <h2>{slogan}</h2>
      </div>

      <div className="footer">
        <Link to={pages[1].url}>{pages[1].title}</Link>
        <Link to={pages[0].url}>{pages[0].title}</Link>
        <Link to={pages[3].url}>{pages[3].title}</Link>
      </div>
    </div>
  )
}

export default Home