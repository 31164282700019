import React from 'react'

export const pt = {
  tips: {
    title: (<>Faça a <span>tecnologia</span> caminhar junto com a estratégia do seu <span>negócio!</span></>),
    items: [
      {
        title: 'Landing Page',
        topics: [
          'Aumentam a conversão',
          'Mensagem focada e segmentada',
          'Fácil de medir o resultado da campanha'
        ]
      },
      {
        title: 'Sistemas Web',
        topics: [
          'Aumento da visibilidade',
          'Diminuição do trabalho "burocrático" e manual',
          'Mais relacionamento com clientes',
          'Aprimoramento da segurança das informações do seu negócio',
          'Organizar e monitorar as informações, recursos, desempenho, metas...'
        ]
      },
      {
        title: 'Aplicativo mobile (android e iOS)',
        topics: [
          'Caráter inovador',
          'Aumento de fidelidade e lealdade com os usuários',
          'Fácil de medir o resultado da campanha',
          'Relacionamento com o usuário',
          'Organizar e monitorar as informações, recursos, desempenho, metas...'
        ]
      }
    ]
  }
}

export const en = {
  tips: {
    title: (<>Make <span>technology</span> go hand in hand with your <span>business</span> strategy!</>),
    items: [
      {
        title: 'Landing Page',
        topics: [
          'Increase conversion',
          'Focused and segmented message',
          'Easy to measure campaign results'
        ]
      },
      {
        title: 'Web System',
        topics: [
          'Increase visibility',
          'Decreased "bureaucratic" and manual work',
          'More customer relations',
          'Improving the security of your business information',
          'Organize and monitoring information, resources, performance, goals...'
        ]
      },
      {
        title: 'Mobile app (android and iOS)',
        topics: [
          'Innovative character',
          'Increased loyalty to users',
          'Easy to measure campaing results',
          'Users relationship',
          'Organize and monitoring information, resources, performance, goals...'
        ]
      }
    ]
  }
}