import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'

import './styles.css'

import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'

import LanguageContext from '../../utils/LanguageContext'
import { pt, en } from '../../languages/Projects'

import OthersProjects from './components/OthersProjects'
import ContactUs from '../../components/ContactUs'

const ProjectsDetail = () => {

  const context = useContext(LanguageContext)

  const projects = context.language === 'pt' ? pt.projects : en.projects

  const { id } = useParams()

  return (
    <>
      <Navbar />
      <div className='projects-detail-container'>
        <div className="content">
          <div className="about">
            <h1>{projects[id].title}</h1>
            <p>{projects[id].content}</p>
            <h3>{projects[id].subtitle}</h3>
            <div className="technologies">
              {projects[id].technologies.map(item => (
                <div key={item} className="chip">{item}</div>
              ))}
            </div>
          </div>

          <div className="pictures">
            <div className="logo">
              <img src={projects[id].logo} alt="web"/>
            </div>
            <div className="devices">
              {projects[id].web ? <img src={projects[id].web} alt="web"/> : null}
              {projects[id].mobile ? <img class="mobile" src={projects[id].mobile} alt="mobile"/> : null}
            </div>
          </div>
        </div>

        <OthersProjects title={projects[id].othersTitle} list={projects[id].others}/>
      </div>
      <ContactUs />
      <Footer />
    </>
  )
}

export default ProjectsDetail