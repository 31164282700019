import React, { useContext } from 'react'

import './styles.css'

import LanguageContext from '../../../../utils/LanguageContext'
import { pt, en } from '../../../../languages/LandPage/Partners'

const Partners = () => {

  const context = useContext(LanguageContext)

  const partners = context.language === 'pt' ? pt.partners : en.partners

  return ( 
    <div className='partners-section' id='partners'>
      <div className="content">
        {partners.map(partner => (
          <div key={partner.id} className="item">
            <img src={partner.img} alt={partner.title}/>
            <h2>{partner.title}</h2>
          </div>
        ))}
      </div>
    </div>
  )
}
 
export default Partners