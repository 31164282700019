import React, { useState } from 'react'
import Routes from './routes'

import './global.css'

import LanguageContext from './utils/LanguageContext'

const App = () => {

  const [language, setLanguage] = useState('pt')

  const toggleLanguage = () => {
    if (language === 'pt')
      setLanguage('en')
    else
      setLanguage('pt')
  }

  return (
    <LanguageContext.Provider value={{language, toggleLanguage}}>
      <Routes />
    </LanguageContext.Provider>
  )
}

export default App