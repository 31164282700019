export const pt = {
  menu: {
    services: 'Serviços',
    process: 'Processo',
    whoWeAre: 'Quem nós somos',
  }
}

export const en = {
  menu: {
    services: 'Services',
    process: 'The Process',
    whoWeAre: 'Who we are',
  }
}