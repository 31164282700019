import lauane from '../../../assets/feedbacks/lauane.jpeg'
import sidney from '../../../assets/feedbacks/sidney.jpeg'
import bira from '../../../assets/feedbacks/bira.jpeg'

export const pt = {
  feedback: [
    {
      id: 0,
      img: sidney,
      title: 'Sidney',
      subtitle: 'Dono - Ideal Negócios Imobiliários',
      content: 'Empresa seria com uma equipe de profissionais fantásticos, entregou o serviço como prometido. Recomendo a Código_Font para quem gosta de qualidade e preço justo.'
    },
    {
      id: 1,
      img: lauane,
      title: 'Lauane',
      subtitle: 'Idealizadora - Charcoal System',
      content: 'Tive uma ótima experiência com a Código_Font. A equipe foi muito atenciosa e solícita desde o primeiro contato até o registro do sistema. Pude acompanhar todas as etapas do processo e dar sugestões, para que o produto ficasse como imaginávamos. Provavelmente faremos outras parcerias!'
    },
    {
      id: 2,
      img: bira,
      title: 'Ubirajara Carneiro',
      subtitle: 'Dono - Milênio Móveis',
      content: 'Estamos bastante satisfeitos com o serviço que vem sendo prestado, a equipe está de parabéns pelo trabalho. Recomendamos a Código_Font com toda certeza'
    },
  ]
}

export const en = {
  feedback: [
    {
      id: 0,
      img: sidney,
      title: 'Sidney',
      subtitle: 'Owner - Ideal Negócios Imobiliários',
      content: 'Company would be with a team of fantastic professionals, delivered the service as promised. I recommend Código_Font to anyone who likes quality and fair price.'
    },
    {
      id: 1,
      img: lauane,
      title: 'Lauane',
      subtitle: 'Creator - Charcoal System',
      content: 'I had a great experience with Código_Font. The staff was very attentive and helpful from the first contact until the registration of the system. I was able to follow all the steps of the process and make suggestions, so that the product was as we imagined. We will probably make other partnerships!'
    },
    {
      id: 2,
      img: bira,
      title: 'Ubirajara Carneiro',
      subtitle: 'Owner - Milênio Móveis',
      content: 'We are very satisfied with the service that has been provided, the team is to be congratulated for the work. We highly recommend Código_Font'
    },
  ]
}