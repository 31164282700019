export const pt = {
  map: {
    title: 'Nossa localização',
    address: {  
      title: 'Venha nos conhecer em:',
      content: 'Av. Oraida Mendes de Castro 6000 Viçosa - MG'
    },
    openTime: {  
      title: 'Estamos abertos de',
      content: 'segunda à sexta, das 8hrs até 22hrs\nsábado e domingo, das 10hrs às 14hrs'
    },
  }
}

export const en = {
  map: {
    title: 'Our location',
    address: {  
      title: 'Come and meet us at:',
      content: 'Av. Oraida Mendes de Castro 6000 Viçosa - MG'
    },
    openTime: {  
      title: 'We are open from',
      content: 'Monday to Friday, 8AM to 10PM\nSaturday and Sunday, 10AM to 2PM'
    },
  }
}