import React from 'react'

import './styles.css'

import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'

import Introduction from './components/Introduction'
import Feedback from './components/Feedback'
import Tips from './components/Tips'
import Clients from './components/Clients'
import Advantages from './components/Advantages'
import OurTeam from './components/OurTeam'
import AboutUs from './components/AboutUs'
import Map from './components/Map'
import Partners from './components/Partners'
import Home from '../Home'

const LandPage = () => {
  return (
    <>
      <Home/>
      <Navbar />
      <div className='landpage-container'>
        <Introduction />
        <Feedback />
        <Tips />
        <Clients />
        <Advantages />
        <OurTeam />
        <AboutUs />
        <Map />
        <Partners />
      </div>
      <Footer />
    </>
  )
}

export default LandPage