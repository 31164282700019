import centev from '../../../assets/centev-logo-2.png'
import lemonade from '../../../assets/lemonade-logo.png'

export const pt = {
  partners: [
    {
      id: 0,
      img: centev,
      title: 'Empresa vinculada'
    },
    {
      id: 1,
      img: lemonade,
      title: 'Processo de aceleração'
    },
  ]
}

export const en = {
  partners: [
    {
      id: 0,
      img: centev,
      title: 'Linked company'
    },
    {
      id: 1,
      img: lemonade,
      title: 'Acceleration process'
    },
  ]
}