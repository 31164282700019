import React, { useContext } from 'react'

import './styles.css'

import img from '../../../../assets/images/contact.png'

import LanguageContext from '../../../../utils/LanguageContext'
import { pt, en } from '../../../../languages/AboutUs/WhoWeAre'

const WhoWeAre = () => {
  const context = useContext(LanguageContext)

  const { whoweare } = context.language === 'pt' ? pt : en

  return (
    <div className='who-we-are-container'>
      <div className="content">
        <div className="col">
          <h1>{whoweare.title}</h1>
          {whoweare.items.map((paragraph, key) => 
            <p key={key}>{paragraph}</p>
          )}
        </div>
        
        <img src={img} alt="services" style={{height: '25rem'}}/>
      </div>
    </div>
  )
}

export default WhoWeAre