import React, { useContext } from 'react'

import './styles.css'

import LanguageContext from '../../../../utils/LanguageContext'
import { pt, en } from '../../../../languages/LandPage/Tips'

const Tips = () => {

  const context = useContext(LanguageContext)

  const tips = context.language === 'pt' ? pt.tips : en.tips

  return ( 
    <div className='tips-section' id='tips'>
      <div className="header">
        <h1>{tips.title}</h1>
      </div>
      <div className="content">
        {tips.items.map((item, key) => (
          <div key={key} className="item">
            <div className="header">
              <h2><span>_</span>{key + 1}</h2>
              <h2><span>{item.title}</span></h2>
            </div>
            <div className="content">
              {item.topics.map((topic, topicKey) => (
                <p key={topicKey}>
                  <span>{`<`}</span>
                  {topic}
                  <span>{`>`}</span>
                </p>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
 
export default Tips