import React, { useContext } from 'react'

import './styles.css'

import LanguageContext from '../../../../utils/LanguageContext'
import { pt, en } from '../../../../languages/LandPage/Map'

const Map = () => {

  const context = useContext(LanguageContext)

  const map = context.language === 'pt' ? pt.map : en.map

  return (
    <div className="map-section">
      <div className="content">
          <h1>{map.title}</h1>
          <div className='row'>
            <p>{map.address.title}</p>
            <p>{map.address.content}</p>
          </div>

          <div className='row'>
            <p>{map.openTime.title}</p>
            <p>{map.openTime.content}</p>
          </div>

          <button onClick={() => window.open("https://www.google.com.br/maps/place/Centro+Tecnol%C3%B3gico+de+Desenvolvimento+Regional+de+Vi%C3%A7osa+CENTEV/@-20.713685,-42.8668497,17z/data=!3m1!4b1!4m5!3m4!1s0xa3675f9344b095:0xfa1bda55ae43a7e9!8m2!3d-20.71369!4d-42.864661", '_blank')}>Google Maps</button>
      </div>
      <div className="map">
        <svg width="660" height="660" viewBox="0 0 632 643" fill="none" xmlns="http://www.w3.org/2000/svg" version="1.1" baseProfile="full">
            <defs>
                <pattern id="Map" patternUnits="userSpaceOnUse" width="660" height="660">
                    <image href="assets/mapa.jpg" x="0" y="0" width="660" height="660" />
                </pattern> 
            </defs>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M343.087 12.5656C405.24 -1.45475 479.538 -12.0325 528.892 28.2634C578.094 68.4351 563.7 145.587 580.901 206.732C597.143 264.471 646.852 319.658 626.761 376.173C606.694 432.62 528.048 437.446 484.323 478.396C430.449 528.85 416.643 635.711 343.087 641.842C271.197 647.833 237.895 551.006 183.644 503.457C136.117 461.802 74.2302 436.933 45.2283 380.783C13.007 318.399 -20.2188 238.069 15.2816 177.492C51.4072 115.848 145.783 132.399 209.61 100.286C258.363 75.7571 289.849 24.5749 343.087 12.5656Z" fill="url(#Map)"/>
        </svg>
      </div>
    </div>
  )
}

export default Map