import image from '../../../assets/images/introduction.png'

export const pt = {
  introduction: {
    title: 'Fazer software é complicado.',
    subtitle: 'A gente simplifica.',
    content: 'Uma empresa que produza um software único e personalizado e que sente a sua dor, não é fácil de se encontrar, mas a Código_Font faz isso.',
    img: image
  }
}
export const en = {
  introduction: {
    title: 'Making software is complicated.',
    subtitle: 'We simplify.',
    content: `A company that makes unique software and feels its pain is not easy to find, but we do.`,
    img: image
  }
}