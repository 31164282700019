import React, { useContext } from 'react'

import './styles.css'

import logo from '../../assets/logo-3.png'
import centev from '../../assets/centev-logo.png'

import facebook from '../../assets/social/facebook.svg'
import whatsapp from '../../assets/social/whatsapp.svg'
import instagram from '../../assets/social/instagram.svg'
import linkedin from '../../assets/social/linkedin.svg'

import LanguageContext from '../../utils/LanguageContext'
import { pt, en } from '../../languages/Footer'

const Footer = () => {
  const context = useContext(LanguageContext)
  
  const map = context.language === 'pt' ? pt.map : en.map

  return ( 
    <div className='footer-container'>
      <div className="social">
        <p>tecnologia@codigofont.com</p>
        <p>(31) 99253-7514</p>
        <ul>
          <li><a href='https://www.facebook.com/C%C3%B3digo_Font-1273386852863593' target='_blank' rel="noopener noreferrer"><img src={facebook} alt="facebook-logo"/></a></li>
          <li><a href='https://api.whatsapp.com/send?phone=553192537514' target='_blank' rel="noopener noreferrer"><img src={whatsapp} alt="whatsapp-logo"/></a></li>
          <li><a href='https://www.instagram.com/codigo_font/?hl=pt-br' target='_blank' rel="noopener noreferrer"><img src={instagram} alt="instagram-logo"/></a></li>
          <li><a href='https://www.linkedin.com/company/c%C3%B3digo-font/' target='_blank' rel="noopener noreferrer"><img src={linkedin} alt="linkedin-logo"/></a></li>
        </ul>
      </div>
      <div className="logos">
        <img src={logo} alt="codigo-font"/>
        <img src={centev} alt="centev-logo"/>
      </div>
      <div className="address">
        <p>Av. Oraida Mendes de Castro, 6000 Viçosa - MG</p>
        <button onClick={() => window.open("https://www.google.com.br/maps/place/Centro+Tecnol%C3%B3gico+de+Desenvolvimento+Regional+de+Vi%C3%A7osa+CENTEV/@-20.713685,-42.8668497,17z/data=!3m1!4b1!4m5!3m4!1s0xa3675f9344b095:0xfa1bda55ae43a7e9!8m2!3d-20.71369!4d-42.864661", '_blank')}>{map}</button>
      </div>
    </div>
  )
}
 
export default Footer