export const pt = {
  aboutus: {
    title: 'Conheça quem somos',
    subtitle: 'Quais as nossas conquistas?',
    button: 'Sobre a Código_Font'
  }
}
export const en = {
  aboutus: {
    title: 'Know who we are',
    subtitle: 'What are our achievements?',
    button: 'About Código_Font'
  }
}