import React, { useContext } from 'react'

import './styles.css'

import LanguageContext from '../../../../utils/LanguageContext'
import { pt, en } from '../../../../languages/AboutUs/Process'

const Process = () => {

  const context = useContext(LanguageContext)

  const { process } = context.language === 'pt' ? pt : en

  return (
    <div className='process-container'>
      <div className="content">
          <h1>{process.title}</h1>
          {process.list.map((item, index) => (
            <div className={index % 2 === 0 ? 'step' : 'step reverse'}>
              <div>
                <h2>{item.title}</h2>
                <p>{item.content}</p>
              </div>
              <div>
                <img src={item.img} alt={item.title}/>
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}

export default Process