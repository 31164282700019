import charcoal from '../../../assets/clients/charcoal.png'
import ideal from '../../../assets/clients/ideal.png'
import primo from '../../../assets/clients/primo.png'
import hc from '../../../assets/clients/hc.png'
import reserve from '../../../assets/clients/reserveja.png'
import milenio from '../../../assets/clients/milenio.svg'
import smartlock from '../../../assets/clients/smartlock.png'
import kamoney from '../../../assets/clients/kamoney.png'

export const pt = {
  clients: {
    title: 'Clientes',
    list: [
      {
        id: 'charcoal',
        img: charcoal,
      },
      {
        id: 'ideal',
        img: ideal,
      },
      {
        id: 'healthy-connection',
        img: hc,
      },
      {
        id: 'primo',
        img: primo,
      },
      {
        id: 'reserve-ja',
        img: reserve,
      },
      {
        id: 'milenio',
        img: milenio,
      },
      {
        id: 'smartlock',
        img: smartlock,
      },
      {
        id: 'kamoney',
        img: kamoney,
      },
    ]
  }
}

export const en = {
  clients: {
    title: 'Clients',
    list: [
      {
        id: 'charcoal',
        img: charcoal,
      },
      {
        id: 'ideal',
        img: ideal,
      },
      {
        id: 'healthy-connection',
        img: hc,
      },
      {
        id: 'reserve-ja',
        img: reserve,
      },
      {
        id: 'primo',
        img: primo,
      },
      {
        id: 'milenio',
        img: milenio,
      },
      {
        id: 'smartlock',
        img: smartlock,
      },
      {
        id: 'kamoney',
        img: kamoney,
      },
    ]
  }
}