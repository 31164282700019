import React, { useState, useContext } from 'react'
import axios from "axios"

import './styles.css'

import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'

import { Snackbar } from "@material-ui/core"

import LanguageContext from '../../utils/LanguageContext'
import { pt, en } from '../../languages/ContactUs'

import whatsapp from '../../assets/social/whatsapp.svg'

const authToken = "e3qKlBL6lFWWWVGFzWGLv65BVCehRBmRRWvzel5mK3qhzmFhLCqVWCv5G3K6"

const ContactUs = () => {

  const context = useContext(LanguageContext)

  const contact = context.language === 'pt' ? pt.contact : en.contact

  const [about, setAbout] = useState('')
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')

  const [open, setOpen] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()

    await axios.post("https://codigo-mails.herokuapp.com/codigoFont/email", {
      mail: email,
      message: about,
      name
    }, { headers: { authToken } })

    setOpen(true)
  }

  return (
    <>
      <Navbar />
      <div className='contact-us-container'>
        <div className="header">
          <h1>{contact.title}</h1>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="col">
            <label htmlFor="about">{contact.about}</label>
            <textarea value={about} onChange={(e) => setAbout(e.target.value)} name="about" id="about" cols="22" rows="10" />
          </div>

          <div className="col">
            <label htmlFor="name">{contact.nameField}</label>
            <input value={name} onChange={(e) => setName(e.target.value)} name="name" id="name" />

            <label htmlFor="email">{contact.emailField}</label>
            <input value={email} onChange={(e) => setEmail(e.target.value)} name="email" id="email" />

            <button type='submit'>{contact.button}</button>
          </div>
        </form>

        <div className="whatsapp">
          <img src={contact.whatsImage} alt="contact"/>
          <div className="content">
            <h1>{contact.whatsTitle}</h1>
            <p>{contact.whatsContent}</p>
            <button onClick={() => window.open("https://api.whatsapp.com/send?phone=553192537514", '_blank')}>
              {contact.whatsButton}<img src={whatsapp} alt="whatsapp-logo"/>
            </button>
          </div>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={() => setOpen(false)}
        message="Contato enviado com sucesso!"
      />
      <Footer />
    </>
  )
}

export default ContactUs