import img from '../../../assets/images/services.png'

export const pt = {
  services: {
    title: 'Expanda e fortaleça sua marca no mercado',
    subtitle: 'Para que servem as consultorias de TI?',
    img,
    items: [
      'Compreensão e análise dos requisitos de TI da organização e do ambiente subjacente',
      'Aconselhamento sobre soluções e serviços de TI',
      'Gerenciamento e supervisão do processo de implementação dos sistemas',
      'Indentificar suas reais necessidades',
      'Definir se a equipe precisa de treinamento'
    ]
  }
}

export const en = {
  services: {
    title: 'Expand and strengthen your brand in the market',
    subtitle: 'What are IT consultancies for?',
    img,
    items: [
      `Understanding and analyzing the organization's IT requirements and the underlying environment`,
      'Advice on IT solutions and services',
      'Management and supervision of the systems implementation process',
      'Identify your real necessities',
      'Define if the team needs training'
    ]
  }
}