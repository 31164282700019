import React, { useState, useContext } from 'react'

import './styles.css'

import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import ContactUs from '../../components/ContactUs'

import LanguageContext from '../../utils/LanguageContext'
import { pt, en } from '../../languages/AboutUs'

import Services from './components/Services'
import Process from './components/Process'
import WhoWeAre from './components/WhoWeAre'

const PAGES = {
  'PROCESS': 0,
  'SERVICES': 1,
  'WE': 2
}

const AboutUs = () => {

  const context = useContext(LanguageContext)

  const [selectedItem, setSelectedItem] = useState(PAGES.PROCESS)

  const { menu } = context.language === 'pt' ? pt : en

  return (
    <>
      <Navbar />
      <div className='about-us-container'>
        <div className="menu">
          <p onClick={() => setSelectedItem(PAGES.PROCESS)}><span>_</span>{menu.process}</p>
          <p onClick={() => setSelectedItem(PAGES.SERVICES)}><span>_</span>{menu.services}</p>
          <p onClick={() => setSelectedItem(PAGES.WE)}><span>_</span>{menu.whoWeAre}</p>
        </div>

        {selectedItem === PAGES.PROCESS && 
          <Process />
        }

        {selectedItem === PAGES.SERVICES && 
          <Services />
        }

        {selectedItem === PAGES.WE && 
          <WhoWeAre />
        }
      </div>
      <ContactUs />
      <Footer />
    </>
  )
}

export default AboutUs