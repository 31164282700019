import React, { useContext } from 'react'

import { FiCheck, FiX } from 'react-icons/fi'

import logo from '../../../../assets/logo-2.png'

import './styles.css'

import LanguageContext from '../../../../utils/LanguageContext'
import { pt, en } from '../../../../languages/LandPage/Advantages'

const Advantages = () => {

  const context = useContext(LanguageContext)

  const advantages = context.language === 'pt' ? pt.advantages : en.advantages

  return ( 
    <div className='advantage-section' id='advantages'>
      <div className="header">
        <h1>
          {advantages.title}
        </h1>
      </div>
      <div className='content'>
        <table>
          <thead>
            <tr>
              <th></th>
              <th><img src={logo} alt="codigo-logo"/></th>
              <th><h2>{advantages.subtitle}</h2></th>
            </tr>
          </thead>
          <tbody>
            {advantages.items.map(item => (
              <tr key={item.id}>
                <td>{item.content}</td>
                <td><FiCheck /></td>
                <td><FiX /></td>
              </tr>
            ))}        
          </tbody>
        </table>   
      </div> 
    </div>
  )
}
 
export default Advantages