import React from 'react'
import { useHistory } from 'react-router-dom'

import './styles.css'

const OthersProjects = ({ title, list }) => {

  const history = useHistory()

  return (
    <div className="others">
      <div className="header">
        <h1>{title}</h1>
      </div>
      <div className="content">
        {list.map((item, key) => (
          <img key={key} src={item.img} alt={item.url} 
            onClick={() => history.push(item.url)}
          />
        ))}
      </div>
    </div>
  )
}

export default OthersProjects