import React, { useContext, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'

import './styles.css'

import logo from '../../assets/logo.png'
import ptFlag from '../../assets/languages/pt.svg'
import enFlag from '../../assets/languages/en.svg'

import LanguageContext from '../../utils/LanguageContext'
import { pt, en } from '../../languages/Navbar'

const Navbar = () => {

  const context = useContext(LanguageContext)

  const history = useHistory()
  
  const pages = context.language === 'pt' ? pt.pages : en.pages

  useEffect(() => {
    const home = document.querySelector(".navbar-container"),
      landpage = document.getElementById("introduction");

    if(window.location.search)
      window.scrollTo(0, landpage.offsetTop);

    window.addEventListener("scroll", () => {
      if(home && landpage) {
        if (window.scrollY > (landpage.offsetTop - 100)) {
          home.classList.remove("hide");
          home.classList.add("fixed");
        } else {
          home.classList.remove("fixed");
          home.classList.add("hide");
        }
      }
    })
  })

  return ( 
    <div className='navbar-container'>
      <div className="logo" onClick={() => history.push("/")}>
        <img src={logo} alt="codigo-font"/>
      </div>
      <ul className='menu'>
        {pages.map((page, key) => (
          <li key={key}>
            <Link to={page.url}>{page.title}</Link>
          </li>
        ))}        
      </ul>
      <img 
        className='language' 
        src={context.language === 'pt' ? ptFlag : enFlag} 
        alt="language"
        onClick={context.toggleLanguage}
      />
    </div>
  )
}
 
export default Navbar