import img from '../../assets/images/contact.png'

export const pt = {
  contact: {
    title: 'Fale conosco sobre o seu produto!',
    phoneTitle: 'Telefone',
    phone: '+55 31 99253-7514',
    email: 'tecnologia@codigofont.com',
    about: 'Sobre o seu produto:',
    nameField: 'Seu nome:',
    emailField: 'Seu email:',
    button: 'Enviar',
    whatsImage: img,
    whatsTitle: 'Fale com a gente pelo Whatsapp!',
    whatsContent: 'Para uma maior agilidade, chame-nos via Whatsapp!',
    whatsButton: 'Contato'
  }
}

export const en = {
  contact: {
    title: 'Tell us about your product!',
    phoneTitle: 'Phone',
    phone: '+55 31 99253-7514',
    email: 'tecnologia@codigofont.com',
    about: 'About your product:',
    nameField: 'Your name:',
    emailField: 'Your email:',
    button: 'Send',
    whatsImage: img,
    whatsTitle: 'Contact us directly via Whatsapp!',
    whatsContent: 'For greater agility, call us via Whatsapp!',
    whatsButton: 'Contact'
  }
}