import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import Home from './views/Home'
import LandPage from './views/LandPage'
import Projects from './views/Projects'
import ProjectsDetail from './views/ProjectsDetail'
import ContactUs from './views/ContactUs'
import AboutUs from './views/AboutUs'

import ScrollToTop from "./ScrollToTop";

const Routes = () => {
    return (
      <BrowserRouter>
        <ScrollToTop />
        <Switch>
          <Route path="/" component={LandPage} exact/>
          <Route path="/projects" component={Projects} exact/>
          <Route path="/projects/:id" component={ProjectsDetail} exact/>
          <Route path="/contact-us" component={ContactUs} exact/>
          <Route path="/about-us" component={AboutUs} exact/>
        </Switch>
      </BrowserRouter>
    )
}

export default Routes