import brainstorm from '../../../assets/images/process-1.svg'
import planejamento from '../../../assets/images/process-2.svg'
import desenvolvimento from '../../../assets/images/process-3.svg'
import homologacao from '../../../assets/images/process-4.svg'

export const pt = {
  process: {
    title: 'O Processo',
    list: [
      {
        title: '01. Brainstorming',
        content: 'Esse é o primeiro passo, nele, faremos uma reunião que visa explorar as ideias criativas que irão solucionar o problema proposto, nas mais diversas perspectivas.',
        img: brainstorm
      },
      {
        title: '02. Planejamento do projeto',
        content: 'O segundo passo é projetar os mínimos detalhes do seu projeto e criar protótipos que darão vida ao seu projeto. Cada detalhe é importante para que seu projeto seja único. É um passo crucial na validação do projeto, imagine que esteja "construindo a planta da sua casa"',
        img: planejamento
      },
      {
        title: '03. Desenvolvimento',
        content: 'Nesse ponto já temos toda a prototipagem feita, e é hora de programar! Nossos desenvolvedores darão seu máximo para entregar o melhor resultado para seu projeto.',
        img: desenvolvimento
      },
      {
        title: '04. Teste e Homologação',
        content: 'Agora nós fazemos uma bateria de testes para garantir a qualidade do produto final, checando ponta-a-ponta para que você receba o melhor a cada semana do seu projeto.',
        img: homologacao
      },
    ]
  }
}

export const en = {
  process: {
    title: 'The Process',
    list: [
      {
        title: '01. Brainstorming',
        content: 'This is the first step, in it, we will have a meeting that aims to explore the creative ideas that will solve the proposed problem, from different perspectives.',
        img: brainstorm
      },
      {
        title: '02. Project planning',
        content: 'The second step is to plan the smallest details of your project and create prototypes that bring your project to life. Each line is important for your project to be unique.',
        img: planejamento
      },
      {
        title: '03. Development',
        content: `At this point we already have all the prototyping done, and it's time to make it real! Our developers sweat to deliver the best result every time.`,
        img: desenvolvimento
      },
      {
        title: '04. Test and Homologation',
        content: 'Now we make a test battery to guarantee the quality of the final product, checking end-to-end so that you receive the best.',
        img: homologacao
      },
    ]
  }
}