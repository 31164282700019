import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import './styles.css'

import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'

import LanguageContext from '../../utils/LanguageContext'
import { pt, en } from '../../languages/Projects'

const Projects = () => {

  const context = useContext(LanguageContext)

  const history = useHistory()

  const { projects } = context.language === 'pt' ? pt : en

  return (
    <>
      <Navbar />
      <div className='projects-container'>
        <div className="content">
          <div className="header">
            <h1>{context.language === 'pt' ? 'Nossos Projetos' : 'Our Projects'}</h1>          
          </div>

          <div className="logos">
            {Object.keys(projects).map(key => (
              <img 
                key={key}
                src={projects[key].logo} 
                alt={projects[key].title}
                onClick={() => history.push(`/projects/${key}`)}
              />
            ))}
          </div>
        </div>     
      </div>
      <Footer />
    </>
  )
}

export default Projects