import React, { useContext } from 'react'

import './styles.css'

import LanguageContext from '../../../../utils/LanguageContext'
import { pt, en } from '../../../../languages/LandPage/OurTeam'

const OurTeam = () => {

  const context = useContext(LanguageContext)

  const ourteam = context.language === 'pt' ? pt.ourteam : en.ourteam

  return ( 
    <div className='ourteam-section' id="ourteam">
      <img src={ourteam.img} alt="ourteam"/>
      <div className="content">
        <h1>{ourteam.title}</h1>
        <p>{ourteam.content}</p>
      </div>
      
    </div>
  )
}
 
export default OurTeam