import React, { useState, useContext } from 'react'

import './styles.css'

import { FiArrowLeft, FiArrowRight } from 'react-icons/fi'

import LanguageContext from '../../../../utils/LanguageContext'
import { pt, en } from '../../../../languages/LandPage/Feedback'

const Feedback = () => {

  const context = useContext(LanguageContext)

  const [selectedClient, setSelectedClient] = useState(0)

  const feedback = context.language === 'pt' ? pt.feedback : en.feedback

  const handleNext = () => {
    if (selectedClient < feedback.length - 1)
      setSelectedClient(selectedClient + 1)
  }

  const handleBack = () => {
    if (selectedClient > 0)
      setSelectedClient(selectedClient - 1)
  }

  if (!feedback) return <></>

  return ( 
    <div className='feedback-section' id='feedback'>
      <div className="card">
        <img src={feedback[selectedClient].img} alt="centev-logo"/>
        <h1>{feedback[selectedClient].title}</h1>
        <h2>{feedback[selectedClient].subtitle}</h2>
        <p>{feedback[selectedClient].content}</p>
      </div>
      <div className="actions">
        <FiArrowLeft size='1.5rem' opacity='0.4' onClick={handleBack}/>
        {feedback.map(client => (
          <div key={client.id} className={client.id === selectedClient ? 'dot selected' : 'dot'}/>
        ))}
        <FiArrowRight size='1.5rem' opacity='0.4' onClick={handleNext}/>
      </div>
    </div>
  )
}
 
export default Feedback