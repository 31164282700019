import React, { useContext } from 'react'

import './styles.css'

import LanguageContext from '../../../../utils/LanguageContext'
import { pt, en } from '../../../../languages/LandPage/AboutUs'
import { useHistory } from 'react-router-dom'

const AboutUs = () => {

  const context = useContext(LanguageContext)

  const aboutus = context.language === 'pt' ? pt.aboutus : en.aboutus

  const history = useHistory();

  return ( 
    <div className='aboutus-section' id='aboutus'>
      <div className="content">
        <h4>{aboutus.subtitle}</h4>
        <h1>{aboutus.title}</h1>
        <button onClick={() => history.push("/about-us")}>{aboutus.button}</button>
      </div>
    </div>
  )
}
 
export default AboutUs