// Charcoal System
import charcoal from '../../assets/clients/charcoal.png'
import charcoalWeb from '../../assets/projects/charcoal/web.png'
import charcoalMobile from '../../assets/projects/charcoal/mobile.png'

// Kardapen
import kardapen from '../../assets/clients/kardapen.png'
import kardapenWeb from '../../assets/projects/kardapen/kardapen-web.svg'
import kardapenMobile from '../../assets/projects/kardapen/kardapen-mobile.svg'

// Ideal
import ideal from '../../assets/clients/ideal.png'
import idealWeb from '../../assets/projects/ideal/ideal-web.svg'
import idealMobile from '../../assets/projects/ideal/ideal-mobile.svg'

// HC
import hc from '../../assets/clients/hc.png'
import hcWeb from '../../assets/projects/hc/hc-web.png'
import hcMobile from '../../assets/projects/hc/hc-mobile.png'

// HC
import reserve from '../../assets/clients/reserveja.png'
import reserveWeb from '../../assets/projects/reserveja/reserveja-web.svg'
import reserveMobile from '../../assets/projects/reserveja/reserveja-mobile.svg'

// Grizu
import grizu from '../../assets/clients/grizu.png'
import grizuMobile from '../../assets/projects/grizu/mobile.svg'

// Milenio
import milenio from '../../assets/clients/milenio.svg'
import milenioWeb from '../../assets/projects/milenio/web.svg'
import milenioMobile from '../../assets/projects/milenio/mobile.svg'

// Aliança
import alianca from '../../assets/clients/alianca.png'
import aliancaWeb from '../../assets/projects/alianca/web.svg'
import aliancaMobile from '../../assets/projects/alianca/mobile.svg'

// Engemais
import engemais from '../../assets/clients/engemais.png'
import engemaisWeb from '../../assets/projects/engemais/web.svg'
import engemaisMobile from '../../assets/projects/engemais/mobile.svg'

//Kamoney
import kamoney from '../../assets/clients/kamoney.png'
import kamoneyMobile from '../../assets/projects/kamoney/mobile.svg'

//SeenControl
import seencontrol from '../../assets/clients/seencontrol.png'
import seencontrolWeb from '../../assets/projects/seencontrol/web.svg'

// Smartlock
import smartlock from '../../assets/clients/smartlock.png'
import smartlockWeb from '../../assets/projects/smartlock/web.svg'
import smartlockMobile from '../../assets/projects/smartlock/mobile.svg'

// Primo Santos
import primo from '../../assets/clients/primo.png'
import primoWeb from '../../assets/projects/primo/web.svg' 

// Odontologica
import odontologica from '../../assets/clients/odontologica.png'
import odontologicaWeb from '../../assets/projects/odontologica/web.svg'
import odontologicaMobile from '../../assets/projects/odontologica/mobile.svg'

// Troni
import troni from '../../assets/clients/troni.png'
import troniWeb from '../../assets/projects/troni/web.svg'
import troniMobile from '../../assets/projects/troni/mobile.svg'

// Maxbot
import maxbot from '../../assets/clients/maxbot.png'
import maxbotMobile from '../../assets/projects/maxbot/mobile.svg'

// Troni
import formullarium from '../../assets/clients/formullarium.png'
import formullariumWeb from '../../assets/projects/formullarium/web.svg'
import formullariumMobile from '../../assets/projects/formullarium/mobile.svg'

export const pt = {
  projects: {
    charcoal: {
      title: 'Sobre o Charcoal System',
      content: 'O Charcoal System veio de uma colaboração entre a Código Font em parceria com a Universidade Federal de Viçosa, mais especificamente com o DER (Departamento de Engenharia Florestal). O intuito era converter uma tese de Doutorado da Prof. Lauane, materializada na forma de planilhas de excel, em um sistema real, de forma a colaborar com o controle da produção de carvão vegetal.',
      subtitle: 'Tecnologias',
      logo: charcoal,
      web: charcoalWeb,
      mobile: charcoalMobile,
      technologies: [
        'ReactJS',
        'NodeJS',
        'Mongodb',
        'React Native',
        'Express'
      ],
      othersTitle: 'Outros Projetos',
      others: [
        {
          url: '/projects/primo',
          img: primo
        },
        {
          url: '/projects/healthy-connection',
          img: hc
        },
        {
          url: '/projects/kardapen',
          img: kardapen
        },
      ],
      contact: {
        title: 'Vamos falar do seu projeto',
        subtitle: 'Gostou do que fazemos?',
        button: 'Encontre conosco!'
      }
    },
    ideal: {
      title: 'Sobre a Ideal Imobiliária',
      content: 'O Site da Imobiliária Ideal, foi um projeto que surgiu a partir de um contato de nossa incubadora (Centev). O intuito principal do projeto era permitir com que visitantes pudessem fazer a locação ou compra de algum imóvel diretamente pela internet.',
      subtitle: 'Tecnologias',
      logo: ideal,
      web: idealWeb,
      mobile: idealMobile,
      technologies: [
        'ReactJS',
        'NodeJS',
        'Express'
      ],
      othersTitle: 'Outros Projetos',
      others: [
        {
          url: '/projects/formullarium',
          img: formullarium
        },
        {
          url: '/projects/smartlock',
          img: smartlock
        },
        {
          url: '/projects/milenio',
          img: milenio
        },
        {
          url: '/projects/seencontrol',
          img: seencontrol
        },
      ],
      contact: {
        title: 'Vamos falar do seu projeto',
        subtitle: 'Gostou do que fazemos?',
        button: 'Encontre conosco!'
      }
    },
    'healthy-connection': {
      title: 'Sobre o Healthy Connection',
      content: 'A partir de uma necessidade real, um casal composto por uma nutricionista e um educador físico perceberam que no mercado não havia um aplicativo que conectava essas 2 profissões com seus pacientes e alunos simultâneamente. A ideia do Healthy Connection surgiu então para unir ambos os acompanhamentos profissionais e elevar a experiência de uma vida fitness para clientes interessados.',
      subtitle: 'Tecnologias',
      logo: hc,
      web: hcWeb,
      mobile: hcMobile,
      technologies: [
        'Python',
        'Django',
        'PostgreSQL',
        'Flutter'
      ],
      othersTitle: 'Outros Projetos',
      others: [
        {
          url: '/projects/charcoal',
          img: charcoal
        },
        {
          url: '/projects/reserve-ja',
          img: reserve
        },
        {
          url: '/projects/kamoney',
          img: kamoney
        },
      ],
      contact: {
        title: 'Vamos falar do seu projeto',
        subtitle: 'Gostou do que fazemos?',
        button: 'Encontre conosco!'
      }
    },
    'reserve-ja': {
      title: 'Sobre o Reserve Já',
      content: 'Partindo do contexto da pandemia de 2020, os estabelecimentos não poderiam receber aglomerações. A partir dessa ideia, nosso cliente queria uma solução com pouco tempo de desenvolvimento e que pudesse antender reservas de forma fácil e rápida. A solução completa foi desenvolvida em apenas 2 semanas!',
      subtitle: 'Tecnologias',
      logo: reserve,
      web: reserveWeb,
      mobile: reserveMobile,
      technologies: [
        'ReactJS',
        'NodeJS',
        'Firebase',
        'React Native',
        'Express'
      ],
      othersTitle: 'Outros Projetos',
      others: [
        {
          url: '/projects/healthy-connection',
          img: hc
        },
        {
          url: '/projects/kardapen',
          img: kardapen
        },
        {
          url: '/projects/odontologica',
          img: odontologica
        },
      ],
      contact: {
        title: 'Vamos falar do seu projeto',
        subtitle: 'Gostou do que fazemos?',
        button: 'Encontre conosco!'
      }
    },
    kardapen: {
      title: 'Sobre o Kardapen',
      content: 'O Kardapen surgiu a partir de uma demanda vinda de um cliente da cidade de Viçosa-MG, a Studebaker. A partir dessa demanda por um gerenciamento mais preciso de seu restaurante e de suas mesas, surgiu o Kardapen. Esse sistema permite que você possa receber uma comanda ao entrar no restaurante, fazer seus pedidos e só receber o mesmo chegando em sua mesa, sem se preocupar com garçons.',
      subtitle: 'Tecnologias',
      logo: kardapen,
      web: kardapenWeb,
      mobile: kardapenMobile,
      technologies: [
        'ReactJS',
        'NodeJS',
        'Mongodb',
        'Express'
      ],
      othersTitle: 'Outros Projetos',
      others: [
        {
          url: '/projects/reserve-ja',
          img: reserve
        },
        {
          url: '/projects/grizu',
          img: grizu
        },
        {
          url: '/projects/milenio',
          img: milenio
        },
      ],
      contact: {
        title: 'Vamos falar do seu projeto',
        subtitle: 'Gostou do que fazemos?',
        button: 'Encontre conosco!'
      }
    },
    grizu: {
      title: 'Sobre o Grizu',
      content: 'O Grizu foi um projeto que surgiu a partir de um contato de nossa incubadora (Centev). O intuito principal do projeto era permitir com que entregadores pudessem resolver entregas dos clientes. O fluxo do aplicativo é simples: uma pessoa precisa enviar uma encomenda do local X para o local Y, para isso, ela coloca sua demanda, e os entregadores acessam e pegam a entrega para fazer.',
      subtitle: 'Tecnologias',
      logo: grizu,
      mobile: grizuMobile,
      technologies: [
        'React Native',
        'Firebase',
        'Realtime Database'
      ],
      othersTitle: 'Outros Projetos',
      others: [
        {
          url: '/projects/reserve-ja',
          img: reserve
        },
        {
          url: '/projects/alianca',
          img: alianca
        },
        {
          url: '/projects/kardapen',
          img: kardapen
        },
        {
          url: '/projects/kamoney',
          img: kamoney
        },
      ],
      contact: {
        title: 'Vamos falar do seu projeto',
        subtitle: 'Gostou do que fazemos?',
        button: 'Encontre conosco!'
      }
    },
    milenio: {
      title: 'Sobre a Milênio Móveis',
      content: 'A Milênio Móveis é um site de venda de móveis de qualidade, localizada na cidade de São Paulo. Seu site foi desenvolvido em 2017, e a partir de 2020, a Código Font passou a ser responsável por executar manutenções no mesmo, garantindo com que o cliente pudesse materializar suas propostas e evoluir suas vendas.',
      subtitle: 'Tecnologias',
      logo: milenio,
      web: milenioWeb,
      mobile: milenioMobile,
      technologies: [
        'AngularJS',
        'MeteorJS',
        'MongoDB',
        'Angular-Meteor'
      ],
      othersTitle: 'Outros Projetos',
      others: [
        {
          url: '/projects/seencontrol',
          img: seencontrol
        },
        {
          url: '/projects/smartlock',
          img: smartlock
        },
        {
          url: '/projects/grizu',
          img: grizu
        },
        {
          url: '/projects/charcoal',
          img: charcoal
        },
      ],
      contact: {
        title: 'Vamos falar do seu projeto',
        subtitle: 'Gostou do que fazemos?',
        button: 'Encontre conosco!'
      }
    },
    alianca: {
      title: 'Sobre a Aliança Veículos',
      content: 'A Aliança Veículos surgiu com a demanda de transformar seu negócio em um modelo digital, de forma que seus clientes pudessem alugar um veículo de forma prática e sem burocracia, mostrando com clareza as vantagens de se alugar um veículo na cidade de Viçosa-MG.',
      subtitle: 'Tecnologias',
      logo: alianca,
      web: aliancaWeb,
      mobile: aliancaMobile,
      technologies: [
        'ReactJS',
        'Firebase',
      ],
      othersTitle: 'Outros Projetos',
      others: [
        {
          url: '/projects/ideal',
          img: ideal
        },
        {
          url: '/projects/odontologica',
          img: odontologica
        },
        {
          url: '/projects/engemais',
          img: engemais
        },
      ],
      contact: {
        title: 'Vamos falar do seu projeto',
        subtitle: 'Gostou do que fazemos?',
        button: 'Encontre conosco!'
      }
    },
    engemais: {
      title: 'Sobre a Engemais',
      content: 'A Engemais surgiu com a demanda de transformar seu negócio em um modelo digital, de forma que seus clientes pudessem visualizar sua história, bem como verificar seus empreendimentos, em destaque o Mirante Sta Clara, localizado na cidade de Viçosa-MG.',
      subtitle: 'Tecnologias',
      logo: engemais,
      web: engemaisWeb,
      mobile: engemaisMobile,
      technologies: [
        'ReactJS',
        'Firebase',
      ],
      othersTitle: 'Outros Projetos',
      others: [
        {
          url: '/projects/reserve-ja',
          img: reserve
        },
        {
          url: '/projects/kamoney',
          img: kamoney
        },
        {
          url: '/projects/primo',
          img: primo
        },
      ],
      contact: {
        title: 'Vamos falar do seu projeto',
        subtitle: 'Gostou do que fazemos?',
        button: 'Encontre conosco!'
      }
    },
    kamoney: {
      title: 'Sobre o Kamoney',
      content: 'O Kamoney é um serviço de pagamentos de fatura, transferência de cryptomoedas, recargas de celular, dentre vários outros recursos. Uma parceria feita com a empresa que fornecia o backend, foi feita, e desenvolvemos então um aplicativo para a mesma, de forma a reconstruir uma interface antiga, trazendo uma cara nova para o sistema.',
      subtitle: 'Tecnologias',
      logo: kamoney,
      mobile: kamoneyMobile,
      technologies: [
        'Flutter',
      ],
      othersTitle: 'Outros Projetos',
      others: [
        {
          url: '/projects/charcoal',
          img: charcoal
        },
        {
          url: '/projects/grizu',
          img: grizu
        },
        {
          url: '/projects/reserve-ja',
          img: reserve
        },
      ],
      contact: {
        title: 'Vamos falar do seu projeto',
        subtitle: 'Gostou do que fazemos?',
        button: 'Encontre conosco!'
      }
    },
    seencontrol: {
      title: 'Sobre o Seen Control',
      content: 'O Seen Control é um sistema de controle e gestão completa de orçamentos, voltado para o controle de mobiliárias e projetos personalizados, inclusive projetos e orçamentos para móveis planejados.',
      subtitle: 'Tecnologias',
      logo: seencontrol,
      web: seencontrolWeb,
      technologies: [
        'Django',
        'PostgreSQL',
        'Python',
        'SQLite'
      ],
      othersTitle: 'Outros Projetos',
      others: [
        {
          url: '/projects/milenio',
          img: milenio
        },
        {
          url: '/projects/grizu',
          img: grizu
        },
        {
          url: '/projects/smartlock',
          img: smartlock
        },
        {
          url: '/projects/ideal',
          img: ideal
        },
      ],
      contact: {
        title: 'Vamos falar do seu projeto',
        subtitle: 'Gostou do que fazemos?',
        button: 'Encontre conosco!'
      }
    },
    smartlock: {
      title: 'Sobre o Smartlock Vistorias',
      content: 'O Smartlock Vistorias é um sistema desenvolvido para funcionamento online/offline, usado para realização e registro de vistorias de entrada e ou saída de imóveis, de forma que o vistoriador possa tirar fotos do local com as avarias, e registrar de forma falada aquilo que for encontrado.',
      subtitle: 'Tecnologias',
      logo: smartlock,
      web: smartlockWeb,
      mobile: smartlockMobile,
      technologies: [
        'ReactJS',
        'Flutter',
        'Firebase',
        'Realtime Database'
      ],
      othersTitle: 'Outros Projetos',
      others: [
        {
          url: '/projects/milenio',
          img: milenio
        },
        {
          url: '/projects/grizu',
          img: grizu
        },
        {
          url: '/projects/seencontrol',
          img: seencontrol
        },
        {
          url: '/projects/ideal',
          img: ideal
        },
      ],
      contact: {
        title: 'Vamos falar do seu projeto',
        subtitle: 'Gostou do que fazemos?',
        button: 'Encontre conosco!'
      }
    },
    primo: {
      title: 'Sobre o Primo Santos',
      content: 'O Primo Santos é um sistema de gestão e controle do funcionamento de um fazendeiro de grande porte, que precisa controlar todas as suas fazendas e ou produções de forma centralizada. Possui controle de várias áreas da fazenda bem como de suas unidades.',
      subtitle: 'Tecnologias',
      logo: primo,
      web: primoWeb,
      technologies: [
        'Django',
        'PostgreSQL',
        'Python',
        'SQLite'
      ],
      othersTitle: 'Outros Projetos',
      others: [
        {
          url: '/projects/charcoal',
          img: charcoal
        },
        {
          url: '/projects/healthy-connection',
          img: hc
        },
        {
          url: '/projects/kardapen',
          img: kardapen
        },
      ],
      contact: {
        title: 'Vamos falar do seu projeto',
        subtitle: 'Gostou do que fazemos?',
        button: 'Encontre conosco!'
      }
    },
    odontologica: {
      title: 'Sobre o Odontológica Viçosa',
      content: 'O Site da Odontológica Viçosa precisava de uma reestruturação completa. Desenvolvido inicialmente utilizando Wordpress, em 2020 foi feita uma refatoração completa utilizando ReactJS, de forma que o site ficasse mais moderno e com a cara da clínica, além de feito uma expansão, extendendo o site para a até então futura unidade Teixeiras-MG.',
      subtitle: 'Tecnologias',
      logo: odontologica,
      web: odontologicaWeb,
      mobile: odontologicaMobile,
      technologies: [
        'ReactJS',
        'Firebase',
      ],
      othersTitle: 'Outros Projetos',
      others: [
        {
          url: '/projects/ideal',
          img: ideal
        },
        {
          url: '/projects/alianca',
          img: alianca
        },
        {
          url: '/projects/milenio',
          img: milenio
        },
        {
          url: '/projects/engemais',
          img: engemais
        },
      ],
      contact: {
        title: 'Vamos falar do seu projeto',
        subtitle: 'Gostou do que fazemos?',
        button: 'Encontre conosco!'
      }
    },
    troni: {
      title: 'Sobre o Troni',
      content: 'O Troni é um sistema que permite com que um vendedor de peças de veículos entre em contato com os demais compradores interessados de forma que o comprador encontre o melhor preço para o produto.',
      subtitle: 'Tecnologias',
      logo: troni,
      web: troniWeb,
      mobile: troniMobile,
      technologies: [
        'NextJS',
        'ReactJS',
        'React Native',
        'Firebase',
        'Realtime Database'
      ],
      othersTitle: 'Outros Projetos',
      others: [
        {
          url: '/projects/maxbot',
          img: maxbot
        },
        {
          url: '/projects/grizu',
          img: grizu
        },
        {
          url: '/projects/milenio',
          img: milenio
        },
      ],
      contact: {
        title: 'Vamos falar do seu projeto',
        subtitle: 'Gostou do que fazemos?',
        button: 'Encontre conosco!'
      }
    },
    maxbot: {
      title: 'Sobre o MaxBot',
      content: 'O MaxBot é um sistema de controle de atendimento via chat completo, permitindo criar anotações, salvar dados do cliente, recebendo notificações e atendendo seus clientes. A Código_Font foi responsável por modelar e produzir a versão aplicativo do mesmo.',
      subtitle: 'Tecnologias',
      logo: maxbot,
      mobile: maxbotMobile,
      technologies: [
        'React Native',
        'REST API',
        'Android',
        'iOS'
      ],
      othersTitle: 'Outros Projetos',
      others: [
        {
          url: '/projects/troni',
          img: troni
        },
        {
          url: '/projects/charcoal',
          img: charcoal
        },
        {
          url: '/projects/milenio',
          img: milenio
        },
      ],
      contact: {
        title: 'Vamos falar do seu projeto',
        subtitle: 'Gostou do que fazemos?',
        button: 'Encontre conosco!'
      }
    },
    formullarium: {
      title: 'Sobre o Formullarium',
      content: 'A Farmácia Formullarium é uma farmácia de manipulação situada na cidade de Viçosa-MG, que sentiu a necessidade de centralizar suas vendas em um ambiente totalmente online. Além da solução de e-commerces tradicionais, o cliente pode ainda enviar uma receita de um remédio manipulado, e ainda receber um feedback de seu orçamento diretamente pelo site, facilitando a vida de seus clientes.',
      subtitle: 'Tecnologias',
      logo: formullarium,
      web: formullariumWeb,
      mobile: formullariumMobile,
      technologies: [
        'NextJS',
        'ReactJS',
        'Express',
        'Firebase',
        'Realtime Database'
      ],
      othersTitle: 'Outros Projetos',
      others: [
        {
          url: '/projects/reserve-ja',
          img: reserve
        },
        {
          url: '/projects/ideal',
          img: ideal
        },
        {
          url: '/projects/milenio',
          img: milenio
        },
      ],
      contact: {
        title: 'Vamos falar do seu projeto',
        subtitle: 'Gostou do que fazemos?',
        button: 'Encontre conosco!'
      }
    },
  }
}

export const en = {
  projects: {
    charcoal: {
      title: 'About Charcoal System',
      content: 'The Charcoal System came from a collaboration between Código Font in partnership with the Federal University of Viçosa, more specifically with the DER (Department of Forest Engineering). The intention was to convert a PhD thesis by Prof. Lauane, materialized in the form of excel spreadsheets, in a real system, in order to collaborate with the control of charcoal production.',
      subtitle: 'Tecnologies',
      logo: charcoal,
      web: charcoalWeb,
      mobile: charcoalMobile,
      technologies: [
        'ReactJS',
        'NodeJS',
        'Mongodb',
        'React Native',
        'Express'
      ],
      othersTitle: 'Other Projects',
      others: [
        {
          url: '/projects/primo',
          img: primo
        },
        {
          url: '/projects/healthy-connection',
          img: hc
        },
        {
          url: '/projects/kardapen',
          img: kardapen
        },
      ],
      contact: {
        title: "Let's talk about your project?",
        subtitle: 'Did you like what we do?',
        button: 'Contact us!'
      }
    },
    ideal: {
      title: 'About Ideal Imobiliária',
      content: 'The Website of Imobiliária Ideal, was a project that arose from a contact from our incubator (Centev). The main purpose of the project was to allow visitors to rent or purchase a property directly over the internet.',
      subtitle: 'Tecnologies',
      logo: ideal,
      web: idealWeb,
      mobile: idealMobile,
      technologies: [
        'ReactJS',
        'NodeJS',
        'Express'
      ],
      othersTitle: 'Other Projects',
      others: [
        {
          url: '/projects/engemais',
          img: engemais
        },
        {
          url: '/projects/smartlock',
          img: smartlock
        },
        {
          url: '/projects/milenio',
          img: milenio
        },
        {
          url: '/projects/seencontrol',
          img: seencontrol
        },
      ],
      contact: {
        title: "Let's talk about your project?",
        subtitle: 'Did you like what we do?',
        button: 'Contact us!'
      }
    },
    'healthy-connection': {
      title: 'About Healthy Connection',
      content: 'From a real need, a couple made up of a nutritionist and a physical educator realized that there was no application on the market that connected these 2 professions with their patients and students simultaneously. The idea of Healthy Connection emerged then to unite both professional accompaniments and elevate the experience of a fitness life for interested clients.',
      subtitle: 'Tecnologies',
      logo: hc,
      web: hcWeb,
      mobile: hcMobile,
      technologies: [
        'Python',
        'Django',
        'PostgreSQL',
        'Flutter'
      ],
      othersTitle: 'Other Projects',
      others: [
        {
          url: '/projects/charcoal',
          img: charcoal
        },
        {
          url: '/projects/reserve-ja',
          img: reserve
        },
        {
          url: '/projects/kamoney',
          img: kamoney
        },
      ],
      contact: {
        title: "Let's talk about your project?",
        subtitle: 'Did you like what we do?',
        button: 'Contact us!'
      }
    },
    'reserve-ja': {
      title: 'About Reserve Já',
      content: 'Starting from the context of the 2020 pandemic, establishments could not receive agglomerations. Based on this idea, our client wanted a solution with little development time and that could book reservations quickly and easily. The complete solution was developed in just 2 weeks!',
      subtitle: 'Tecnologies',
      logo: reserve,
      web: reserveWeb,
      mobile: reserveMobile,
      technologies: [
        'ReactJS',
        'NodeJS',
        'Firebase',
        'React Native',
        'Express'
      ],
      othersTitle: 'Other Projects',
      others: [
        {
          url: '/projects/healthy-connection',
          img: hc
        },
        {
          url: '/projects/kardapen',
          img: kardapen
        },
        {
          url: '/projects/odontologica',
          img: odontologica
        },
      ],
      contact: {
        title: "Let's talk about your project?",
        subtitle: 'Did you like what we do?',
        button: 'Contact us!'
      }
    },
    kardapen: {
      title: 'About Kardapen',
      content: 'Kardapen arose from a demand from a customer in the city of Viçosa-MG, Studebaker. From this demand for a more precise management of your restaurant and your tables, Kardapen emerged. This system allows you to receive a command when entering the restaurant, place your orders and only receive it when you arrive at your table, without worrying about waiters.',
      subtitle: 'Tecnologies',
      logo: kardapen,
      web: kardapenWeb,
      mobile: kardapenMobile,
      technologies: [
        'ReactJS',
        'NodeJS',
        'Mongodb',
        'Express'
      ],
      othersTitle: 'Other Projects',
      others: [
        {
          url: '/projects/reserve-ja',
          img: reserve
        },
        {
          url: '/projects/grizu',
          img: grizu
        },
        {
          url: '/projects/milenio',
          img: milenio
        },
      ],
      contact: {
        title: "Let's talk about your project?",
        subtitle: 'Did you like what we do?',
        button: 'Contact us!'
      }
    },
    grizu: {
      title: 'About Grizu',
      content: 'Grizu was a project that came from a contact from our incubator (Centev). The main purpose of the project was to allow delivery personnel to resolve customer deliveries. The flow of the application is simple: a person needs to send an order from location X to location Y, for that, they place their demand, and the delivery personnel access and take delivery to do.',
      subtitle: 'Tecnologies',
      logo: grizu,
      mobile: grizuMobile,
      technologies: [
        'React Native',
        'Firebase',
        'Realtime Database'
      ],
      othersTitle: 'Other Projects',
      others: [
        {
          url: '/projects/reserve-ja',
          img: reserve
        },
        {
          url: '/projects/alianca',
          img: alianca
        },
        {
          url: '/projects/kardapen',
          img: kardapen
        },
        {
          url: '/projects/kamoney',
          img: kamoney
        },
      ],
      contact: {
        title: "Let's talk about your project?",
        subtitle: 'Did you like what we do?',
        button: 'Contact us!'
      }
    },
    milenio: {
      title: 'About Milênio Móveis',
      content: 'Milênio Móveis is a website for selling quality furniture, located in the city of São Paulo. Its website was developed in 2017, and from 2020, the Font Code became responsible for performing maintenance on it, ensuring that the customer could materialize its proposals and evolve its sales.',
      subtitle: 'Tecnologies',
      logo: milenio,
      web: milenioWeb,
      mobile: milenioMobile,
      technologies: [
        'AngularJS',
        'MeteorJS',
        'MongoDB',
        'Angular-Meteor'
      ],
      othersTitle: 'Other Projects',
      others: [
        {
          url: '/projects/seencontrol',
          img: seencontrol
        },
        {
          url: '/projects/smartlock',
          img: smartlock
        },
        {
          url: '/projects/grizu',
          img: grizu
        },
        {
          url: '/projects/charcoal',
          img: charcoal
        },
      ],
      contact: {
        title: "Let's talk about your project?",
        subtitle: 'Did you like what we do?',
        button: 'Contact us!'
      }
    },
    alianca: {
      title: 'About Aliança Veículos',
      content: 'Aliança Veículos came up with the demand to transform its business into a digital model, so that its customers could rent a vehicle in a practical way and without bureaucracy, clearly showing the advantages of renting a vehicle in the city of Viçosa-MG.',
      subtitle: 'Tecnologies',
      logo: alianca,
      web: aliancaWeb,
      mobile: aliancaMobile,
      technologies: [
        'ReactJS',
        'Firebase',
      ],
      othersTitle: 'Other Projects',
      others: [
        {
          url: '/projects/ideal',
          img: ideal
        },
        {
          url: '/projects/odontologica',
          img: odontologica
        },
        {
          url: '/projects/engemais',
          img: engemais
        },
      ],
      contact: {
        title: "Let's talk about your project?",
        subtitle: 'Did you like what we do?',
        button: 'Contact us!'
      }
    },
    engemais: {
      title: 'About Engemais',
      content: 'Engemais came up with the demand to transform its business into a digital model, so that its customers could view its history, as well as check out their developments, in particular the Mirante Sta Clara, located in the city of Viçosa-MG.',
      subtitle: 'Tecnologies',
      logo: engemais,
      web: engemaisWeb,
      mobile: engemaisMobile,
      technologies: [
        'ReactJS',
        'Firebase',
      ],
      othersTitle: 'Other Projects',
      others: [
        {
          url: '/projects/reserve-ja',
          img: reserve
        },
        {
          url: '/projects/kamoney',
          img: kamoney
        },
        {
          url: '/projects/primo',
          img: primo
        },
      ],
      contact: {
        title: "Let's talk about your project?",
        subtitle: 'Did you like what we do?',
        button: 'Contact us!'
      }
    },
    kamoney: {
      title: 'About Kamoney',
      content: 'Kamoney is an invoice payment service, cryptocurrency transfer, cell phone recharges, among many other resources. A partnership with the company that provided the backend was made, and we then developed an application for it, in order to reconstruct an old interface, bringing a new face to the system.',
      subtitle: 'Tecnologies',
      logo: kamoney,
      mobile: kamoneyMobile,
      technologies: [
        'Flutter',
      ],
      othersTitle: 'Other Projects',
      others: [
        {
          url: '/projects/charcoal',
          img: charcoal
        },
        {
          url: '/projects/grizu',
          img: grizu
        },
        {
          url: '/projects/reserve-ja',
          img: reserve
        },
      ],
      contact: {
        title: "Let's talk about your project?",
        subtitle: 'Did you like what we do?',
        button: 'Contact us!'
      }
    },
    seencontrol: {
      title: 'About Seen Control',
      content: 'Seen Control is a complete budget control and management system, aimed at controlling furniture and custom projects, including projects and budgets for planned furniture.',
      subtitle: 'Tecnologies',
      logo: seencontrol,
      web: seencontrolWeb,
      technologies: [
        'Django',
        'PostgreSQL',
        'Python',
        'SQLite'
      ],
      othersTitle: 'Other Projects',
      others: [
        {
          url: '/projects/milenio',
          img: milenio
        },
        {
          url: '/projects/grizu',
          img: grizu
        },
        {
          url: '/projects/smartlock',
          img: smartlock
        },
        {
          url: '/projects/ideal',
          img: ideal
        },
      ],
      contact: {
        title: "Let's talk about your project?",
        subtitle: 'Did you like what we do?',
        button: 'Contact us!'
      }
    },
    smartlock: {
      title: 'About Smartlock Vistorias',
      content: 'The Smartlock Vistorias is a system developed for online / offline operation, used for conducting and registering surveys of entry and / or exit of properties, so that the surveyor can take pictures of the location with the damage, and record what is spoken. found.',
      subtitle: 'Tecnologies',
      logo: smartlock,
      web: smartlockWeb,
      mobile: smartlockMobile,
      technologies: [
        'ReactJS',
        'Flutter',
        'Firebase',
        'Realtime Database'
      ],
      othersTitle: 'Other Projects',
      others: [
        {
          url: '/projects/milenio',
          img: milenio
        },
        {
          url: '/projects/grizu',
          img: grizu
        },
        {
          url: '/projects/seencontrol',
          img: seencontrol
        },
        {
          url: '/projects/ideal',
          img: ideal
        },
      ],
      contact: {
        title: "Let's talk about your project?",
        subtitle: 'Did you like what we do?',
        button: 'Contact us!'
      }
    },
    primo: {
      title: 'About Primo Santos',
      content: 'Primo Santos is a management and control system for the operation of a large farmer, who needs to control all his farms and or productions in a centralized way. It has control over several areas of the farm as well as its units.',
      subtitle: 'Tecnologies',
      logo: primo,
      web: primoWeb,
      technologies: [
        'Django',
        'PostgreSQL',
        'Python',
        'SQLite'
      ],
      othersTitle: 'Other Projects',
      others: [
        {
          url: '/projects/charcoal',
          img: charcoal
        },
        {
          url: '/projects/healthy-connection',
          img: hc
        },
        {
          url: '/projects/kardapen',
          img: kardapen
        },
      ],
      contact: {
        title: "Let's talk about your project?",
        subtitle: 'Did you like what we do?',
        button: 'Contact us!'
      }
    },
    odontologica: {
      title: 'About Odontological Viçosa',
      content: 'The Odontological Viçosa website needed a complete restructuring. Initially developed using Wordpress, in 2020 a complete refactoring was done using ReactJS, so that the site would be more modern and with the look of the clinic, in addition to an expansion, extending the site to the until then future unit Teixeiras-MG.',
      subtitle: 'Tecnologies',
      logo: odontologica,
      web: odontologicaWeb,
      mobile: odontologicaMobile,
      technologies: [
        'ReactJS',
        'Firebase',
      ],
      othersTitle: 'Other Projects',
      others: [
        {
          url: '/projects/ideal',
          img: ideal
        },
        {
          url: '/projects/alianca',
          img: alianca
        },
        {
          url: '/projects/milenio',
          img: milenio
        },
        {
          url: '/projects/engemais',
          img: engemais
        },
      ],
      contact: {
        title: "Let's talk about your project?",
        subtitle: 'Did you like what we do?',
        button: 'Contact us!'
      }
    },
    troni: {
      title: 'About Troni',
      content: 'Troni is a system that allows a vehicle parts seller to contact other interested buyers so that the buyer finds the best price for one product.',
      subtitle: 'Tecnologies',
      logo: troni,
      web: troniWeb,
      mobile: troniMobile,
      technologies: [
        'ReactJS',
        'React Native',
        'Firebase',
        'Realtime Database'
      ],
      othersTitle: 'Other Projects',
      others: [
        {
          url: '/projects/reserve-ja',
          img: reserve
        },
        {
          url: '/projects/grizu',
          img: grizu
        },
        {
          url: '/projects/milenio',
          img: milenio
        },
      ],
      contact: {
        title: "Let's talk about your project?",
        subtitle: 'Did you like what we do?',
        button: 'Contact us!'
      }
    },
    maxbot: {
      title: 'About MaxBot',
      content: 'MaxBot is a complete chat control system, allowing you to create notes, save customer data, receive notifications and serve your customers. Código_Font was responsible for modeling and producing the application version of it.',
      subtitle: 'Tecnologies',
      logo: maxbot,
      mobile: maxbotMobile,
      technologies: [
        'React Native',
        'REST API',
        'Android',
        'iOS'
      ],
      othersTitle: 'Other Projects',
      others: [
        {
          url: '/projects/troni',
          img: troni
        },
        {
          url: '/projects/charcoal',
          img: charcoal
        },
        {
          url: '/projects/milenio',
          img: milenio
        },
      ],
      contact: {
        title: "Let's talk about your project?",
        subtitle: 'Did you like what we do?',
        button: 'Contact us!'
      }
    },
    formullarium: {
      title: 'About Formullarium',
      content: 'Formullarium Pharmacy is a handling pharmacy located in the city of Viçosa-MG, which felt the need to centralize its sales in a totally online environment. In addition to the traditional e-commerce solution, the customer can also send a prescription for a manipulated drug, and still receive feedback on their budget directly through the website, making life easier for their customers.',
      subtitle: 'Tecnologies',
      logo: formullarium,
      web: formullariumWeb,
      mobile: formullariumMobile,
      technologies: [
        'NextJS',
        'ReactJS',
        'Express',
        'Firebase',
        'Realtime Database'
      ],
      othersTitle: 'Other Projects',
      others: [
        {
          url: '/projects/reserve-ja',
          img: reserve
        },
        {
          url: '/projects/ideal',
          img: ideal
        },
        {
          url: '/projects/milenio',
          img: milenio
        },
      ],
      contact: {
        title: "Let's talk about your project?",
        subtitle: 'Did you like what we do?',
        button: 'Contact us!'
      }
    },
  }
}