export const pt = {
    whoweare: {
        title: 'Quem somos?',
        items: [
        'Somos uma empresa que todo o time se preocupa com o sucesso do projeto dos nossos clientes, essa é nossa filosofia, ou seja, somos o maior interessado no sucesso do seu projeto.',
        'Nascemos em uma cidade do interior, polo de educação e que está se tornando polo em inovação e empreendedorimos, nos localizamos em  Viçosa - Minas Gerais onde esta sediada uma das melhores universidades do pais.',
        'Nosso time trabalha com união extrema buscando sempre trazer a melhor solução para nossos clientes. Nossa cultura se basea em proporcionar a melhor comunicação e condições de trabalho para todos os membros, tanto nossos clientes quanto internamente.'
        ]
    }
}

export const en = {
    whoweare: {
        title: 'Who we are?',
        items: [
        'We are a company that the whole team is concerned with the success of our client\'s project, this is our philosophy, that is, we are the most interested in the success of your project.',
        'We were born in a city in the countryside, a pole of education and which is becoming a pole for innovation and entrepreneurship, we are located in Viçosa - Minas Gerais, where one of the best universities of the country is located.',
        'Our team works with extreme union always seeking to bring the best solution for our customers. Our culture is based on providing the best communication and working conditions for all members, both our customers and internally.'
        ]
    }
}