import React, { useContext } from 'react'
import { useHistory } from "react-router-dom";

import './styles.css'

import LanguageContext from '../../../../utils/LanguageContext'
import { pt, en } from '../../../../languages/LandPage/Clients'

const Clients = () => {

  const context = useContext(LanguageContext)

  const clients = context.language === 'pt' ? pt.clients : en.clients

  const history = useHistory()

  return ( 
    <div className='clients-section' id='clients'>
      <div className="header">
        <h1>{clients.title}</h1>
      </div>
      <div className="content">
        {clients.list.map(item => (
          <img onClick={() => history.push(`/projects/${item.id}`)} key={item.id} src={item.img} alt={item.id}/>
        ))}
      </div>
    </div>
  )
}
 
export default Clients