export const pt = {
  pages: [
    {  
      title: 'Principal',
      url: '/?home'
    },
    {
      title: 'Sobre',
      url: '/about-us'
    },
    {  
      title: 'Projetos',
      url: '/projects'
    },
    {
      title: 'Contato',
      url: '/contact-us'
    },
  ],
  slogan: "Fazer software é complicado. A gente simplifica."
}

export const en = {
  pages: [
    {  
      title: 'Home',
      url: '/?home'
    },
    {
      title: 'About Us',
      url: '/about-us'
    },
    {  
      title: 'Projects',
      url: '/projects'
    },
    {
      title: 'Contact Us',
      url: '/contact-us'
    },
  ],
  slogan: "Making software is complicated. We simplify."
}
