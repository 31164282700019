import React, { useContext } from 'react'

import './styles.css'

import LanguageContext from '../../../../utils/LanguageContext'
import { pt, en } from '../../../../languages/AboutUs/Services'

const Services = () => {

  const context = useContext(LanguageContext)

  const { services } = context.language === 'pt' ? pt : en

  return (
    <div className='services-container'>
      <div className="content">
        <div className="col">
          <h1>{services.title}</h1>
          <h4>{services.subtitle}</h4>
          {services.items.map((item, key) => (
            <p key={key}>
              <span>{`<`}</span>
                {item}
              <span>{`>`}</span>
            </p>
          ))}
        </div>
        
        <img src={services.img} alt="services" style={{height: '25rem'}}/>
      </div>
    </div>
  )
}

export default Services