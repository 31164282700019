import React, { useContext } from 'react'

import './styles.css'

import LanguageContext from '../../../../utils/LanguageContext'
import { pt, en } from '../../../../languages/LandPage/Introduction'

const Introduction = () => {

  const context = useContext(LanguageContext)

  const introduction = context.language === 'pt' ? pt.introduction : en.introduction

  return ( 
    <div className='introduction-section' id='introduction'>
      <div className="content">
        <h1>{introduction.title}</h1>
        <h2>{introduction.subtitle}</h2>
        <p>{introduction.content}</p>
      </div>
      <img src={introduction.img} alt="introduction"/>
    </div>
  )
}
 
export default Introduction