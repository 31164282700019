import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import './styles.css'

import LanguageContext from '../../utils/LanguageContext'
import { pt, en } from '../../languages/ContactUsComponent'

const ContactUs = () => {

  const context = useContext(LanguageContext)

  const contact = context.language === 'pt' ? pt.contact : en.contact

  const history = useHistory()

  return (
    <div className="contact-us-component">
      <h3>{contact.subtitle}</h3>
      <h1>{contact.title}</h1>
      <button onClick={() => history.push('/contact-us')}>{contact.button}</button>
    </div>
  )
}

export default ContactUs