import image from '../../../assets/images/our-team.png'

export const pt = {
  ourteam: {
    title: 'Nossa equipe',  
    content: 'A Código_Font tem uma equipe de desenvolvimento selecionada a dedo, assim, garantimos a qualidade que você merece.',
    img: image
  }
}
export const en = {
  ourteam: {
    title: 'Our team',  
    content: 'Código_Font has a hand-selected development team, so we guarantee the quality you deserve.',
    img: image
  }
}