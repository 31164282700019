export const pt = {
  contact: {
    title: 'Vamos falar do seu projeto',
    subtitle: 'Gostou do que fazemos?',
    button: 'Encontre conosco!'
  }
}

export const en = {
  contact: {
    title: `Let's talk about your project`,
    subtitle: 'Did you like what we do?',
    button: 'Find us!'
  }
}